.container {
    max-width: unset !important;
    padding: 0;
}




@media screen and (max-width:768px) {
    .hover_team {
        opacity: 1;
    }

    #equipo {
        padding: 50px 0px;
    }

    #equipo .persona {
        height: 300px;
        background-position: 50%;
        background-size: cover;
        width: 90%;
        margin: 30px auto;
    }

    #equipo h1 {
        font-size: 22px;
        letter-spacing: 3px;
        color: #eaeaea;
        padding: 20px 19px;
    }

    #equipo h2 {
        font-size: 17px;
        padding: 0px 26px;
        color: #eaeaea;
        margin-bottom: 3px;
    }
}

.bg-light {
    background-color: transparent !important;
    color: #00CCD6 !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #eaeaea;
    letter-spacing: 1px;
    margin: 0 5px;
    transition: all 1s;
}

.navbar-light .navbar-nav .plataforma {
    color: #00CCD6;
    padding: 5px 10px;
    border: 1px solid #00CCD6;
    border-radius: 5%;
}

.navbar-light .navbar-nav .plataforma:hover {
    background: #00CCD6;
    color: #EAEAEA !important;
    padding: 5px 10px;
    border: 1px solid #00CCD6;
    border-radius: 5%;
}

nav {
    position: fixed !important;
    z-index: 100;
    width: 100%;
    padding: 20px 20px !important;
}

.navbar-light .navbar-toggler {
    color: white;
    border-color: rgba(0, 0, 0, 0);
}

.navbar-brand img {
    height: 30px;
    margin-left: 30px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-light .navbar-nav .nav-link:hover {
    background-color: rgba(0, 203, 214, 0.247);
}

.bottom_absolute {
    bottom: 0;
    position: absolute;
    height: 25vh;
    width: 100%;
    z-index: 30;

    .left_section {
        position: absolute;
        left: 3%;
        bottom: 15%;

        a {
            font-size: 15px;
            display: block;
            margin: 15px 0px;
            transition: all 1s;
        }

        i {
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
        }
    }

    .middle_section {
        .line {
            height: 75px;
            width: 2px;
        }

        top: 25%;
        padding: 0 2%;
        right: 48%;
        cursor: pointer;
        position: absolute;
        transition: all 1s;
        animation: fadeOutDown;
        animation-iteration-count: infinite;
        animation-duration: 4s;
    }

    .right_section {
        bottom: 35%;
        position: absolute;
        right: 2%;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);

        a {
            cursor: pointer;
            font-size: 12px;
            letter-spacing: 5px;
            position: relative;
            transition: all 1s;
            text-decoration: none !important;
        }

        a::before {
            content: "";
            left: 90%;
            top: 50%;
            position: absolute;
            width: 80px;
            height: 1px;
            background-color: white;
            visibility: visible;
            -webkit-transform: scaleX(.2);
            -moz-transform: scaleX(.2);
            -ms-transform: scaleX(.2);
            -o-transform: scaleX(.2);
            transform: scaleX(.2);
            -webkit-transition: all 0.3s ease-in-out 0s;
            -moz-transition: all 0.3s ease-in-out 0s;
            -ms-transition: all 0.3s ease-in-out 0s;
            -o-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
        }

        a:hover::before {
            background-color: rgb(96, 96, 96) !important;

            -webkit-transform: scaleX(.5);
            -moz-transform: scaleX(.5);
            -ms-transform: scaleX(.5);
            -o-transform: scaleX(.5);
            transform: scaleX(.5);
        }
    }
}

.blackFill {
    fill: white;
    stroke: white;
}

.whiteFill {
    fill: rgb(102, 102, 102);
    stroke: rgb(102, 102, 102);
}



.black {
    color: white;
}

.black:hover {
    color: rgb(133, 133, 133) !important;
}

.blackBG {
    background-color: white;
}

.blackBG:hover {
    background-color: rgb(133, 133, 133) !important;
}

.white {
    color: rgb(102, 102, 102);
}

.white:hover {
    color: rgb(80, 80, 80) !important;
}

.whiteBG {
    background-color: rgb(102, 102, 102);
}

.whiteBG:hover {
    background-color: rgb(50, 50, 50) !important;
}


.center {
    text-align: center;
}

body {
    background-color: $darker-blue;

}

.text-center {
    text-align: center;
}

#hero {
    background-image: url(../images/home.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .interior_box {
        text-align: center;

        img {
            max-width: 80%;
            display: block;
            margin: 0 auto;
            font-weight: bolder;
        }

        h3 {
            margin: 30px 0px;
            background-color: white;
            color: $darker-blue;
            padding: 5px 20px;
            width: auto;
            border-radius: 5px;
            font-weight: bolder;
            font-size: 1.5em;
            display: inline-block;
        }

        a {
            margin: 5px 20px;
            font-size: 1.2em;
            color: $blue;
            transition: all 1s;
        }

        a:hover {
            color: white;
        }
    }
}

#nosotros {
    padding: 20px 25%;

    h1 {
        color: white;
        font-size: 2em;
    }

    hr {
        color: $blue;
        border: 2px solid $blue;
        width: 30%;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    p {
        color: white;
        font-size: 0.9em;
    }
}

#utilidades {
    h1 {
        margin-top: 40px;
        color: white;
        font-size: 2em;
    }

    hr {
        color: $blue;
        border: 2px solid $blue;
        width: 30%;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

#servicios {
    padding: 50px 10%;

    h3 {
        font-size: 2em;
        font-weight: bolder;
    }

    .box_servicios {
        width: 90%;
        background-color: red;
    }

    .extranjero_img_box {
        display: block;
        width: 35%;
        height: 230px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../images/extranjero.png);
        float: right;
    }

    .nacional_img_box {
        display: block;
        width: 35%;
        height: 230px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../images/nacional.png);
        float: left;
    }

    .box_nacional {
        margin-left: 10%;
    }

    p {
        min-height: 6em;
    }

    .extranjero_box {
        width: 65%;
        display: inline-block;
        background-color: $blue;
        padding: 30px 70px;
        padding-left: 30%;
        text-align: right;
        height: 230px;
    }

    .nacional_box {
        padding: 30px 70px;
        padding-right: 30%;
        display: inline-block;
        width: 65%;
        background-color: white;
        height: 230px;
    }

    .extranjero_left,
    .nacional_left,
    .extranjero_right,
    .nacional_right {
        padding: 5px 10px;
        border: 1px solid $darker-blue;
        border-radius: 5px;
        transition: all .5s;
        margin: 0 3px;
        cursor: pointer;
    }

    .extranjero_left:hover,
    .nacional_left:hover,
    .extranjero_right:hover,
    .nacional_right:hover {
        background-color: $darker-blue;
        color: white;
    }
}

#contacto {
    padding: 30px 10%;

    h1 {
        font-size: 1.8em;
        font-weight: bolder;
    }

    .left_contacto {
        background-color: $blue;
        text-align: left;
        padding: 50px 70px;
        padding-left: 15%;

        label {
            display: block;
            color: grey;
        }

        input,
        textarea {
            display: block;
            width: 100%;
            border: 1px solid transparent;
            transition: all 1s;
            border-radius: 5px;
            background-color: #badbf2;
            resize: none;
        }

        input:focus,
        textarea:focus {
            border: 1px solid $darker-blue;
        }

        hr {
            border: 2px solid white;
            width: 10%;
            margin: 20px 0px;
        }

        button {
            margin-top: 30px;
            width: 100%;
            color: white;
            background-color: $darker-blue;
            padding: 5px 0px;
            text-align: center;
            font-weight: bolder;
            border: none;
            border-radius: 5px;
            transition: all 1s;
        }

        button:hover {
            background-color: black;
        }

    }

    .right_contacto {
        padding: 50px 40px;
        text-align: left;

        h2 {
            color: white;
            margin-top: 40%;
        }

        hr {
            border: 2px solid $blue;
            width: 10%;
            margin: 20px 0px;
        }

        h4 {
            color: $blue;
            font-size: 1em;
            margin-left: 15px;
            margin-top: 10px;
            margin-bottom: 0px;
        }

        a,
        p {
            font-size: .9em;
            margin-left: 15px;
            margin-top: 0;
            color: white;
        }

        a {
            text-decoration: none;
            transition: all 1s;
        }

        a:hover {
            color: $blue;
        }

        .redes_sociales {
            display: inline-block;
            padding: 5px 10px;
            height: 32px;
            width: 32px;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 5px;
        }

        .twitter {
            background-color: #26aced;
        }

        .facebook {
            background-color: #3b5a99;
        }

        .twitter:hover {
            color: white;
            background-color: darken(#26aced, 20%);
        }

        .facebook:hover {
            color: white;
            background-color: darken(#3b5a99, 20%);
        }
    }
}


@media only screen and (max-width: 600px) {
    #hero {
        .interior_box {
            h3 {
                font-size: 1em;
            }

            a {
                font-size: 1em;
            }
        }
    }

    #nosotros {
        padding: 20px 5%;
    }

    #servicios {
        padding: 50px 5%;

        .box_servicios {
            width: 100%;
            background-color: none;
        }
    }

    #utilidades {
        img {
            width: 90%;
        }
    }

    #contacto {
        h1 {
            font-size: 1.2em;
        }

        .left_contacto {
            padding: 30px 20px;
        }

        .right_contacto {
            padding: 30px 20px;

            h2 {
                margin-top: 20px;
            }

            h4,
            a,
            p {
                margin-left: 0;
            }

            .twitter {
                margin-right: 10px;
            }
        }
    }

    footer{
        padding: 0;
    }
    .navbar-collapse {
        background: rgba(40, 41, 62, 79%) !important;
    }
    #servicios .extranjero_box {
        width: 100%;
        display: block;
        background-color: #98caec;
        padding: 30px 10px;
    }
    #servicios h3 {
        font-size: 1.3em;
    }
    #servicios .nacional_box {
        padding: 30px 10px;
        display: block;
        width: 100%;
    }
    #servicios .extranjero_img_box, #servicios .nacional_img_box {
        float: none;
        width: 100%;
    }
    #servicios .box_nacional {
        margin-left: 0%;
    }
}
