// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #bada55;
$dorado: #B8824B;
$principal: #283651;
$gris: #1F1F1D;

$blue: #98caec;
$darker-blue: #202136;